<template>
  <div style="display:flex;flex-direction:column;height: 100%;">
    <div style="display:flex;flex:1;overflow:scroll;">
      <van-sidebar v-model="activeKey" @change="handleTabChange">
        <van-sidebar-item v-for="(item,index) in leftList" :key="index" :title="item.productName">11221121</van-sidebar-item>
      </van-sidebar>
      <div style="flex:1;overflow:scroll;">
        <van-divider :style="{  margin: '5px 0px' }"></van-divider>
        <div v-for="(item,index) in rightList" :key="index" style="display:flex;flex-direction: column;">
          <div style="flex:1;display: flex;">
            <div style="border: solid #1989fa 2px;border-radius: 10px;display: flex;justify-content: center; align-items: center;font-weight: 900;width: 85px;height: 85px;">{{item.productType}}</div>
            <div style=" text-align:center;flex: 1;">
              <div style="text-align:center;">{{item.productName}}</div>
              <div style="text-align:center;margin-top: 10px;">
                <van-stepper v-model="item.selectCount" :name="index" @change="handleSelectChange" min="0" :default-value="0" theme="round"></van-stepper>
              </div>
            </div>
          </div>
          <van-divider style="margin:5px 0px;"></van-divider>
        </div>
      </div>
    </div>
    <div style="display: flex; height: 50px;">
      <div style="padding:7px 50px">
        <van-badge :content="getSelectProductsCount">
          <img src="../../assets/gouwuche.png" @click="handleGouWuChe" />
        </van-badge>
      </div>
      <div style="flex:1">
        <van-button type="info" block round to="WapProductSelectSubmit">去下单</van-button>
      </div>
    </div>
    <van-popup v-model="showPPP" position="bottom" round :style="{height:'60%'}">
      <div style="flex:1;overflow:scroll;">
        <van-divider>已选产品</van-divider>
        <div v-for="(item,index) in getSelectProducts" :key="index">
          <div style="flex:1;display: flex;">
            <div style="border: solid #1989fa 2px;border-radius: 10px;display: flex;justify-content: center; align-items: center;font-weight: 900;width: 85px;height: 85px;">{{item.productType}}</div>
            <div style=" text-align:center;flex: 1;">
              <div style="text-align:center;">{{item.productName}}</div>
              <div style="text-align:center;margin-top: 10px;">
                <van-stepper v-model="item.selectCount" :name="index" @change="handleSelectChange" min="0" :default-value="0" theme="round"></van-stepper>
              </div>
            </div>
          </div>
          <van-divider style="margin:5px 0px;"></van-divider>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import { mapGetters, mapMutations } from "vuex";

Vue.use(Vant);
export default {
  data() {
    return {
      activeKey: 0,
      productList: [],
      leftList: [],
      rightList: [],
      showPPP: false,
    };
  },
  computed: {
    ...mapGetters(["getSelectProducts", "getSelectProductsCount"]),
  },
  methods: {
    ...mapMutations(["setSelectProducts"]),
    handleTabChange(i) {
      let that = this;
      that.rightList = that.productList.filter((p) => {
        return p.pId == that.leftList[i].id;
      });
    },
    handleSelectChange(v, d) {
      let that = this;
      that.setSelectProducts(that.rightList[d.name]);
    },
    handleGouWuChe() {
      let that = this;
      that.showPPP = true;
    },
    handleXiaDan() {},
  },
  mounted() {
    let that = this;
    that.axios.post("Product/GetListAll").then(function (response) {
      that.productList = response.data.data;
      that.leftList = that.productList.filter((p) => {
        return p.pId == 0;
      });
      that.handleTabChange(0);
    });
  },
};
</script>

<style>
</style>